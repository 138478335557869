* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Optional: for better width control */
}

body {
  background-color: black !important;
  font-family: 'Courier New', monospace !important;
  color: #0f0 !important; /* Matrix green */
  /* overflow: hidden; /* Prevents scrolling */
  position: relative; /* Needed for absolute positioning of the canvas */
}

.scrollable-container {
  max-height: 100vh; /* Viewport height */
  width: 100%; /* Enables vertical scrolling */
}

.full-width-container {
  width: 100vw; /* Full viewport width */
  max-width: none; /* Removes the max-width restriction */
}

/* Matrix Loading Indicator */
.matrix-loading {
  color: #0f0;
  font-family: 'Courier New', monospace;
  text-align: center;
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% { opacity: 0; }
}

h1 {
  font-family: 'Courier New', monospace;
  color: white; /* White for contrast */
  font-size: 4rem;
  font-weight: bold;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #0f0, 0 0 20px #0f0; /* White glow for contrast */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 10px; /* Padding around the text */
  border-radius: 10px; /* Rounded corners for the background box */
  display: block; /* Block display */
  margin: 0 auto; /* Centering horizontally */
  text-align: center; /* Center text alignment */
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #0f0, 0 0 40px #0f0;
  }
  to {
    text-shadow: 0 0 15px #fff, 0 0 25px #fff, 0 0 35px #0f0, 0 0 45px #0f0;
  }
}

.typewriter {
  display: inline-block;
}

.typewriter:after {
  text-align: left; /* Align text to the left */
  content: '|';
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

.text-center iframe {
  background-color: white !important;
}

.alert {
  text-align: center;
  background-color: #1a1a1a; /* Dark background for alerts */
  color: #0f0; /* Matrix green text for alerts */
  border: 1px solid #0f0; /* Optional: green border for alerts */
}

#matrixCanvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Puts the canvas behind your content */
}